<template>
  <div>
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div class="p-b-15 d-flex flex-column flex-md-row form-phone-wrapper">
        <div class="phone-wrapper w-100">
          <div class="form-group mb-0">
            <vue-tel-input v-if="simpleInput" style="width: 100%" class="inputFocused"  v-model="val"
                           :autoDefaultCountry="false" :dropdownOptions="{
                        width: '20rem',
                        showDialCodeInList: true,
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showSearchBox: true
                      }" :inputOptions="{
                        placeholder: $t('search.phoneNumberLabel'),
                      }" mode="national" :defaultCountry="countryForPhoneNumber" styleClasses="input" @input="getValidate"
                           :validCharactersOnly="true"></vue-tel-input>
            <input class="form-control" :placeholder="$t('search.phoneNumberLabel')" v-else type="text" autocomplete="off" v-model="phoneVal" />
          </div>
        </div>
        <div class="switcher-wrapper flex-shrink-sm-1 d-flex align-items-center p-t-10 pt-md-0">
          <div class="switchContainer">
            <div class="form-check form-switch" >
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                     v-model="simpleInput">
              <label for="flexSwitchCheckChecked">{{ $t('settings.simplified_phone') }}</label>
            </div>
          </div>
        </div>
      </div>
      <OptionsSearch param="isdn" @push="setPayload" @invalid="setInvalid" />
      <div class="d-grid" v-if="!disallowFreeSpace">
        <b-button variant="primary" class="btn"  type="submit" :disabled="$v.payload.$invalid">
          {{ $t("search.search") }}
        </b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>
    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <b-button variant="primary" class="mt-3" @click="resolveExistResult('update')"   block >{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2"  :disabled="!showBtn" @click="resolveExistResult('open')">{{$t('search.open')}}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { VueTelInput } from "vue-tel-input";
import {isValidPhoneNumber} from 'libphonenumber-js';
import "vue-tel-input/dist/vue-tel-input.css";
import {mapGetters} from "vuex";
import OptionsSearch from "@/components/search/optionsSearch";
import { required } from "vuelidate/lib/validators";
import {validationMixin} from 'vuelidate';
import openResult from "@/mixins/openResult";

export default {
    components:{
      VueTelInput,
      OptionsSearch
    },
    mixins: [validationMixin, openResult],
    data() {
        return {
            payload: {
                param: 'isdn',
                value:''
            },
            countryForPhoneNumber: localStorage.getItem("selected_country_isdn") || 'UA',
            phoneObj: {},
            phoneRaw: "",
            input: {},
            modalDialog: false,
            showBtn: true,
            invalid: true,
            val: "",
            phoneVal: "+",
            simpleInput: true
        }
    },

    validations() {
      return {
        payload: {
          value: {
            required,
            validPhone: (val) => {
              if(!this.simpleInput) {
                return isValidPhoneNumber(val)
              } else {
                return typeof this.input.valid === "undefined" ? false : this.input.valid
              }
            }
          },
          optionsValid() {
            return !this.invalid
          }
        }
      }
    },
    computed:{
      ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
      ...mapGetters("auth", ["user"]),
    },
    methods:{
      setPayload(data) {
        this.payload = Object.assign(this.payload, data)
      },
      setInvalid(optionsInvalid) {
        this.invalid = optionsInvalid
      },
      getValidate(phone, phoneObj) {
        this.countryForPhoneNumber = localStorage.getItem('selected_country_isdn');
        this.phoneObj = phoneObj;
        this.phoneRaw = phone;
        let payload = JSON.parse(JSON.stringify(phoneObj));
        this.input = payload;
        this.payload.value = payload.number || "";
        if(typeof phoneObj.country !== 'undefined') {
          localStorage.setItem("selected_country_isdn", phoneObj.country.iso2)
        }
      },
      beforeSearch() {
        let value = this.payload.value;
        let searchesExist = this.$store.state.contacts.searches.filter((item) => {
          return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
        });
        let withFail = searchesExist.filter((item) => {
          return !!item.error;
        });
        if (searchesExist.length) {
          this.modalDialog = true;
          if (withFail.length) {
            this.showBtn = false;
          } else {
            this.showBtn = true;
          }
        } else {
          this.addSearch();
          try {
            this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
            if(this.optionsForAnalytics.length) {
              this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
            }
          } catch (e) {
            console.log('ignore error');
          }
        }
      },
      addSearch() {
          this.$store.dispatch("contacts/search", {
            payload: Object.assign({}, this.payload),
          }).then(() => {
            this.$store.dispatch('ui/getFreeSpaceInfo')
          });
          this.payload.value = null;
          this.val = "";
          this.phoneVal = '+';
          delete this.payload.filters
          delete this.payload.alt_values
      },
      resolveExistResult(value) {
        let result = {
          keyVal: `${this.payload.param}_${this.payload.value}_${this.user.login}`,
          loaded: true,
        };
        switch (value) {
          case "open":
            this.openResult(result);
            break;
          case "update":
            this.addSearch(false);
            this.modalDialog = false;
            break;
          case "new":
            this.addSearch(true);
            this.modalDialog = false;
            break;
        }
      },
    },
    watch: {
      phoneVal(val) {
        if(!this.simpleInput) {
          this.payload.value = val.replace(/^(\+)|\D/g, "$1");
        }
      },
      simpleInput(val) {
        if(val) {
          this.payload.value = null
        }
      }
    }
}
</script>
<style>
  @media (min-width: 768px) {
    .switcher-wrapper {
      width: fit-content;
      white-space: nowrap;
      padding-left: 15px;
    }
  }
</style>